<template>
  <v-row>
    <v-col cols="4">
      <v-autocomplete
        v-model="selected.day"
        :label="`${ $t('global.day')}`"
        :items="dayItems"
        :rules="dateRules"
        :no-data-text="noData"
        @input="onChange($event, 'day')"
      />
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="selected.month"
        :label="`${ $t('global.month')}`"
        item-text="text"
        item-value="value"
        :items="monthItem"
        :rules="dateRules"
        :no-data-text="noData"
        @input="onChange($event, 'month')"
      />
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="selected.year"
        :label="`${ $t('global.year') }`"
        :items="yearItems"
        :rules="dateRules"
        :no-data-text="noData"
        @input="onChange($event, 'year')"
      />
    </v-col>
  </v-row>
</template>
<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    days: {
      type: String,
      default: null
    },
    month: {
      type: String,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    birthday: {
      type: Boolean,
      default: true
    },
    setdefault: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      content: this.value,
      selected: {
        day: '',
        month: '',
        year: ''
      }
    }
  },
  computed: {
    noData () {
      return this.$t('global.notFound')
    },
    dayItems () {
      const list = []
      const date = new Date().getDate()
      const dayNum = this.$dayjs(`${this.selected.year}-${this.selected.month}`).daysInMonth()
      const day = parseInt(dayjs().format('D'))
      const month = parseInt(dayjs().format('M'))
      const year = parseInt(dayjs().format('YYYY'))
      if (Number.isNaN(dayNum)) {
        if (this.birthday && this.selected.year === year && this.selected.month === month) {
          for (let i = date; i <= day; i++) {
            list.push({ text: i, value: i })
          }
        } else {
          for (let i = 1; i <= 31; i++) {
            list.push({ text: i, value: i })
          }
        }
        return list
      }
     if (this.birthday && this.selected.year === year && this.selected.month === month) {
        for (let i = 1; i < day; i++) {
          list.push({ text: i, value: i })
        }
      } else {
        for (let i = 1; i <= dayNum; i++) {
          list.push({ text: i, value: i })
        }
      }
      return list
    },
    monthItem () {
      const list = []
      const month = [
        this.$t('global.monthItems.jan'),
        this.$t('global.monthItems.feb'),
        this.$t('global.monthItems.mar'),
        this.$t('global.monthItems.apr'),
        this.$t('global.monthItems.may'),
        this.$t('global.monthItems.jun'),
        this.$t('global.monthItems.jul'),
        this.$t('global.monthItems.aug'),
        this.$t('global.monthItems.sep'),
        this.$t('global.monthItems.oct'),
        this.$t('global.monthItems.nov'),
        this.$t('global.monthItems.dec')
      ]
      const date = new Date().getMonth()
      const year = new Date().getFullYear()
      if (this.birthday && this.selected.year === year) {
        for (let i = 0; i <= date; i++) {
          list.push({ text: month[i], value: i + 1 })
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          list.push({ text: month[i - 1], value: i })
        }
      }
      return list
    },
    yearItems () {
      const list = []
      if (this.max) {
        const max = this.max || 10
        for (let i = this.$dayjs().year(); i <= this.$dayjs().year() + max; i++) {
          const buddhistYear = i + 543
          if (this.$i18n.locale === 'th') {
            list.push({ text: buddhistYear, value: i })
          } else {
            list.push({ text: buddhistYear - 543, value: i })
          }
        }
      } else if (this.min) {
        const min = this.min || 120
        for (let i = this.$dayjs().year() - min; i <= this.$dayjs().year(); i++) {
          const buddhistYear = i + 543
          if (this.$i18n.locale === 'th') {
            list.push({ text: buddhistYear, value: i })
          } else {
            list.push({ text: buddhistYear - 543, value: i })
          }
        }
      } else {
        for (let i = this.$dayjs().year() - 120; i <= this.$dayjs().year(); i++) {
          const buddhistYear = i + 543
          if (this.$i18n.locale === 'th') {
            list.push({ text: buddhistYear, value: i })
          } else {
            list.push({ text: buddhistYear - 543, value: i })
          }
        }
      }
      return list.reverse()
    },
    dateRules () {
      return [(v) => !!v || this.$t('global.validate')]
    }
  },
  watch: {
    setdefault (val) {
      this.content = val
    }
  },
  mounted () {
    this.defaultDate()
  },
  methods: {
    onChange () {
      if (this.selected.day && this.selected.month && this.selected.year) {
        const m = this.selected.month < 10 ? `0${this.selected.month}` : this.selected.month
        const d = this.selected.day < 10 ? `0${this.selected.day}` : this.selected.day
        const date = new Date(`${this.selected.year}-${m}-${d}`).toISOString()
        this.$emit('input', date)
      }
    },
    defaultDate () {
      if (this.setdefault) {
        const date = new Date(this.setdefault)
        this.selected.day = date.getDate()
        this.selected.month = date.getMonth() + 1
        this.selected.year = date.getFullYear()
      }
    }
  }
}
</script>
